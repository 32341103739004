<template>
  <div>
    <Header/>
    <Nav/>
    <div class="topImg">
      <div>المعلومات الأساسية للمعرض</div>
    </div>
    <div class="content">
      <div class="content-top"><span>لصفحة</span> <span>لصفحة الرئيسية > مرك</span><img class="iconhom" src="https://www.cas-expo.org.cn/web/images/home.png" alt=""></div>
      <div class="videolist-content" @click="gotoVideo">
        <video>
          <source
            src="./video/videos.mp4"
            type="video/mp4"
          >
        </video>
        <img src="https://www.cas-expo.org.cn/web/images/play.png" alt="">
        
      </div>
    </div>
    <div class="bottomImg">
      <img src="@/assets/img/logofont.png" alt="">
      <div>
        <span>:ICP {{'كل الحقوق محفوظة لدى الأمانة العامة لمعرض الصين والدول العربية'}} </span> <span class="number"> {{'38110050-7'}} </span> <span> {{'نينغشيا رقم تسجيل'}} </span>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Nav from '@/components/Nav'
export default {
  components: {
    Header,
    Nav
  },
  methods: {
    gotoVideo() {
      this.$router.push({
        name: 'video'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.topImg {
  background: url('~@/assets/img/itemBanner.png');
  min-height: 320px;
  background-size: cover;
  line-height: 320px;
  position: relative;
  div {
    text-align: center;
    font-size: 48px;
    color: #fff;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
}
.bottomImg {
  background: url('~@/assets/img/itemBottom.png');
  background-size: cover;
  min-height: 287px;
  text-align: center;
  color: rgba(255,255,255,0.65);
  font-family: 'GeezaPro';
  img {
    width: 384px;
    object-fit: cover;
    margin-top: 52px;
    margin-bottom: 57px;
  }
  .number {
    margin-right: 29px;
  }
}
.content {
  position: relative;
  min-height: 738px;
  width: 1200px;
  margin: 0 auto;
  padding-top: 24px;
  .videolist-content {
    position: relative;
    width: 380px;
    cursor: pointer;
    video {
      width: 380px;
      // height: 231px;
    }
    img {
      position: absolute;
      left: 157px;
      top: 87px;
      width: 48px;
      height: 48px;
    }
    .bottom-desc {
      width: 380px;
      height: 96px;
      background: #F8F8F9;
      position: relative;
      top: -3px;
      line-height: 24px;
      font-size: 16px;
      padding: 12px;
      color: #000000;
      box-sizing: border-box;
      text-align: right;
    }
    &:hover {
      .bottom-desc {
        background: #0861DD;
        color: #fff;
      }
    }
  }
}
.content-top {
  padding-bottom: 20px;
  text-align: right;
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 48px;
  color: #333333;
  .iconhom {
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }
}
</style>